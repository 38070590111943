import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
// External components
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Tooltip,
  Typography,
} from "@mui/material";
// Graphql
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { client_EB_api } from "../../graphql/client";
import { GET_BILL_BY_ID, GET_EB_BY_ID, RESEND_EMAIL } from "../../graphql";
// Components
import OptionCard from "../OptionCard";
import NotesForm from "../NotesForm";
import Loading from "../Loading";
import ResumeCard from "../ResumeCard";
import ClientCard from "../ClientCard";
import PaymentDataCard from "../PaymentDataCard";
import CustomLoading from "../CustomLoading";
import SendBillScreen from "../SendBillScreen";
// Hooks
import useElectronicBill from "../../hooks/useElectronicBill";
import useCreditNote from "../../hooks/useCreditNote";
import useDebitNote from "../../hooks/useDebitNote";
// Context
import { useAuth, useModal } from "../../context";
import { useNotifications } from "../../context/NotificationsContext";
import { useElectronic } from "../../context/ElectronicContext";
// Enums
import {
  BILL_TYPES_URL_PARAMS,
  ElectronicBillTypes,
  ELECTRONIC_BILLS_DOCUMENT_TYPES,
} from "../../Enums/ElectronicBill";
import { ElectronicBillErrors, UserErrors } from "../../Enums/Errors";
// Utils
import { customToast as toast } from "../../utils";
import { billConfigurationEmptyState } from "../../InitialStates/ElectronicContext";
import ProductCard from "../ProductCard";
import { getUserByIdentification, validateRoute } from "../../utils/helpers";
// Icons
import addProductIcon from "../../assets/icons/add_product_icon.svg";
import withoutIcon from "../../assets/icons/withoutProduct-icon.svg";
import { UPDATE_CONFIGURATION_BILL } from "../../graphql/billConfiguration/billConfiguration.mutation";
import ScheduledBillCard from "../ScheduledBillCard/ScheduledBillCard";
import RegisterReceiver from "../RegisterReceiver";
import useUsers from "../../hooks/useUsers";

const submitText = {
  CreditNote: "Enviar Nota de crédito",
  DebitNote: "Enviar nota de débito",
};

function PaymentComponent() {
  const [noteInfo, setNoteInfo] = useState({ code: "", reason: "" });
  const [sendingBill, setSendingBill] = useState(null);
  const [registeredTicket, setRegisteredTicket] = useState(false);
  const [sentDraft, setSentDraft] = useState(false);
  const [loadState, setLoadState] = useState({
    loadingState: "loading",
    isLoading: false,
  });
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { id, billType } = useParams();
  const { saveCreditNote, loadCreditNote, canSaveCN } = useCreditNote();
  const { canSaveDN, saveDebitNote } = useDebitNote();
  const {
    saveTempralBill: saveTemporalBill,
    saveEBillFormBill,
    loadElectronicBill,
    loadDraftBill,
  } = useElectronicBill();

  const client = useApolloClient();

  const {
    lineDetail,
    LineDetailPlace,
    receiver,
    electronicBill,
    setElectronicBill,
    setLineDetail,
    useCoinDetail,
    isBilling,
    setIsBilling,
    resetReceiver,
    resetElectronicBill,
    setScheduledBill,
    scheduledBill,
    setReceiver,
    setAddress,
    setClientGroup,
  } = useElectronic();
  const { addNewClient, dataClientsGroups, createGroup, refetchGroups } =
    useUsers();
  const { resetDefaultCoin } = useCoinDetail;
  const { permissions } = useAuth();
  const navigate = useNavigate();
  const { haveActionPermission, noAccessMessage } = permissions;
  const { setModalOpen } = useModal();
  const { createNotification, refetchNotifications, setNotifications } =
    useNotifications();
  const [updateConfigurationBill] = useMutation(UPDATE_CONFIGURATION_BILL);
  const [submitInvoiceText, setSubmitInvoiceText] = useState(
    "Generar Tiquete Electrónico",
  );

  const canRead = haveActionPermission(
    "Read",
    "/electronic-bills",
    LineDetailPlace,
  );
  if (!canRead) {
    toast.warning(noAccessMessage("Leer", "Fac Electrónica"));
    navigate(`/`);
  }
  const {
    data: dataBill,
    loading,
    refetch,
  } = useQuery(GET_BILL_BY_ID, {
    variables: {
      id: sendingBill?.id || id,
    },
    skip: !id,
  });

  const [billConfiguration, setBillConfiguration] = useState({
    FK_Bill: Number(id),
    date: dayjs(),
    time: dayjs(),
    Day: dayjs().day(),
    Month: dayjs().month() + 1,
    Year: dayjs().year(),
    Hour: dayjs().utc().hour(),
    Minute: dayjs().minute(),
    Frequency: 1,
    Active: 0,
    Next_Execution: dayjs().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
  });

  const isCreditNoteView = useMemo(
    () => billType === BILL_TYPES_URL_PARAMS.CREDIT_NOTE,
    [billType],
  );
  const isDebitNoteView = useMemo(
    () => billType === BILL_TYPES_URL_PARAMS.DEBIT_NOTE,
    [billType],
  );
  const isNote = useMemo(
    () => isCreditNoteView || isDebitNoteView,
    [isDebitNoteView, isCreditNoteView],
  );

  const isDraft = useMemo(
    () => billType === BILL_TYPES_URL_PARAMS.DRAFT,
    [billType],
  );

  const handleSaveAsTemp = async () => {
    if (lineDetail.length === 0) return;
    const bill = await saveTemporalBill();

    setElectronicBill(prev => ({ ...prev, id: bill.id }));
    setLineDetail(prev =>
      prev.map(detail => ({
        ...detail,
        detail_id: bill.Detail.find(
          _detail => _detail?.ProductFeature?.id === detail?.id,
        )?.id,
      })),
    );
    setSentDraft(true);
  };

  const resetBill = useCallback(() => {
    resetElectronicBill();
    resetDefaultCoin();
    setLineDetail([]);
    resetReceiver();
  }, [resetDefaultCoin, resetElectronicBill, resetReceiver, setLineDetail]);

  useEffect(() => {
    if (!isBilling) resetBill();
    if (!id) {
      setScheduledBill(billConfigurationEmptyState);
      return;
    }
    if (isDraft) {
      loadDraftBill(id);
      return;
    }
    if (isDebitNoteView) {
      loadCreditNote(id);
    } else {
      loadElectronicBill(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(
    () => {
      if (isBilling) setIsBilling(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBilling],
  );

  const getSaveButtonText = useMemo(
    () =>
      electronicBill.id
        ? `La factura será guardada automáticamente`
        : "Guardar en borrador",
    [electronicBill.id],
  );

  const ToastsFactory = error => {
    switch (error.message) {
      case UserErrors.EMPTY_CHECKED_PRODUCTS:
        toast.error("Seleccione al menos un producto");
        break;
      default:
        toast.error("Error desconocido");
    }
  };

  const sendCreditNote = async () => {
    try {
      const validation = canSaveCN();
      if (validation.hasError) {
        ToastsFactory(validation);
        return;
      }
      const {
        data: { createCreditNote },
      } = await saveCreditNote(noteInfo);
      if (createCreditNote?.error === ElectronicBillErrors.ACCESS_TOKEN) {
        toast.error("Error al registrar el tiquete, revise sus credenciales");
        resetBill();
        await handleCreateNotification(
          "Error - Nota de Crédito",
          "Error al registrar el tiquete, revise sus credenciales",
          "",
          id ? `/electronic-bills/${id}/Draft` : "/electronic-bills/",
        );
        setLoadState(prev => ({ loadingState: "error", isLoading: false }));
        return;
      }
      toast.success("¡Nota de Crédito guardada con éxito!");
      await handleCreateNotification(
        "Exito - Nota de Crédito",
        "¡Nota de Crédito guardada con éxito!",
        "",
        `/ElectronicBillView/${createCreditNote.id}/created/${BILL_TYPES_URL_PARAMS.CREDIT_NOTE}`,
      );
      navigate(
        `/ElectronicBillView/${createCreditNote.id}/created/${BILL_TYPES_URL_PARAMS.CREDIT_NOTE}`,
      );
    } catch {
      toast.error("Ha ocurrido un error");
      await handleCreateNotification(
        "Error - Nota de Crédito",
        "Ha ocurrido un error al enviar la nota de crédito",
        "",
        id ? `/electronic-bills/${id}/CreditNote` : "/electronic-bills/",
      );
    }
  };

  const sendDebitNote = async () => {
    try {
      const validation = canSaveDN();
      if (validation.hasError) {
        ToastsFactory(validation);
        return;
      }
      const {
        data: { createDebitNote },
      } = await saveDebitNote(noteInfo);
      if (createDebitNote?.error === ElectronicBillErrors.ACCESS_TOKEN) {
        toast.error("Error al registrar el tiquete, revise sus credenciales");
        resetBill();
        setLoadState(prev => ({ loadingState: "error", isLoading: false }));
        return;
      }
      toast.success("¡Nota de Débito guardada con éxito!");
      navigate(
        `/ElectronicBillView/${createDebitNote.id}/created/${BILL_TYPES_URL_PARAMS.DEBIT_NOTE}`,
      );
    } catch {
      toast.error("Ha ocurrido un error");
    }
  };

  const handleSendBill = async () => {
    setLoadState(prev => ({ loadingState: "loading", isLoading: true }));
    try {
      if (billType === BILL_TYPES_URL_PARAMS.CREDIT_NOTE) {
        sendCreditNote();
        return;
      }
      if (billType === BILL_TYPES_URL_PARAMS.DEBIT_NOTE) {
        sendDebitNote();
        return;
      }
      if (!billType || isDraft) {
        const bill = !isDraft ? await saveTemporalBill() : electronicBill;
        const resp = await saveEBillFormBill(bill.id);
        if (resp.error) {
          handleShowError(resp.error);
          await handleCreateNotification(
            "Error - Factura",
            "Ha ocurrido un error al intentar registrar la factura",
            "",
            bill?.id
              ? `/electronic-bills/${bill.id}/Draft`
              : "/electronic-bills",
          );
          return;
        }
        if (bill && resp.id) {
          setSendingBill(bill);
          toast.success("¡Factura  guardada con éxito!");
          handleCreateNotification(
            "Éxito - Factura",
            "¡Factura Guardada Exitosamente!",
            "",
            `/ElectronicBillView/${resp.id}/created/${BILL_TYPES_URL_PARAMS.ELECTRONIC_BILL}}`,
          );
          navigate(
            `/ElectronicBillView/${resp.id}/created/${BILL_TYPES_URL_PARAMS.ELECTRONIC_BILL}`,
          );
        }
        setRegisteredTicket(true);
      }
    } catch (err) {
      toast.error("Error al registrar el tiquete");
      await handleCreateNotification(
        "Error - Factura",
        "Error al registrar el tiquete",
        "",
        "/electronic-bills",
      );
      resetBill();
    }
    setLoadState(prev => ({ ...prev, isLoading: false }));
    setNotifications(await refetchNotifications());
  };

  const getBill = async idEB => {
    const bill = await client.query({
      query: GET_EB_BY_ID,
      variables: {
        id: idEB,
      },
    });
    return bill;
  };

  const handleSendEmails = async receivers => {
    let idElectronicBill = 0;
    let features = [];

    if (billType !== BILL_TYPES_URL_PARAMS.DRAFT) {
      let bill = await getBill(electronicBill.id);
      idElectronicBill = bill?.data?.EBill?.Bill?.id;

      if (
        bill?.data?.EBill?.ElectronicBillDocumentType?.id ===
        ELECTRONIC_BILLS_DOCUMENT_TYPES.CREDIT_NOTE.toString()
      ) {
        bill = await getBill(bill?.data?.EBill?.ReferencedDocument?.id);
        idElectronicBill = bill?.data?.EBill?.Bill?.id;
        bill?.data?.EBill?.ElectronicBillDetail.map(detail =>
          features.push(detail.id),
        );
      }
    } else {
      features = lineDetail.map(({ value }) => value);
    }

    await client_EB_api.mutate({
      mutation: RESEND_EMAIL,
      variables: {
        id:
          billType === BILL_TYPES_URL_PARAMS.DRAFT || sentDraft
            ? electronicBill.id
            : idElectronicBill,
        idElectronicBillFeatures: features,
        to: receivers,
      },
    });
    toast.success("Email enviado con éxito!");
  };

  const openSendEmailModal = () => {
    setModalOpen(
      true,
      <SendBillScreen
        receptorRequired
        billingEmail={receiver?.Receiver_Email}
        handleCloseModal={() => setModalOpen(false)}
        handleSend={handleSendEmails}
        place={LineDetailPlace}
      />,
    );
  };

  const handleShowError = error => {
    if (error === ElectronicBillErrors.ACCESS_TOKEN) {
      toast.error("Error al registrar el tiquete, revise sus credenciales");
      setLoadState(prev => ({ loadingState: "error", isLoading: false }));
      navigate("/electronic-bills", { replace: true });
    }
  };

  const handleCreateNotification = async (title, body, icon, clickAction) => {
    await createNotification(title, body, icon, clickAction);
    setNotifications(await refetchNotifications());
  };

  const getType = () => {
    if (isNote)
      return isDebitNoteView
        ? ElectronicBillTypes.DebitNote
        : ElectronicBillTypes.CreditNote;
    return ElectronicBillTypes.FacturaElectronica;
  };

  const availableSendDocument = () => {
    const { code, reason } = noteInfo;

    const selected =
      Array.isArray(lineDetail) && lineDetail.length > 0
        ? lineDetail.filter(p => p.checked === true)
        : [];

    if ((!code || !reason || !selected.length) && isNote) {
      return true;
    }
    if (!isNote) {
      return !lineDetail.length;
    }

    return false;
  };

  const handleNextExecution = (frequency, date) => {
    const formatFrequency = frequency.toString();
    const currentDay = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    let formatNextExecution;

    if (formatFrequency === "1") {
      formatNextExecution = dayjs(date).add(1, "day");
    }
    if (formatFrequency === "2") {
      formatNextExecution = dayjs(date).add(7, "day");
    }
    if (formatFrequency === "3") {
      formatNextExecution = dayjs(date).add(1, "month");
    }
    if (date > currentDay) {
      formatNextExecution = dayjs(date)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    }
    return formatNextExecution;
  };

  const roundToNext15Minutes = date => {
    const minutes = dayjs(date).minute();
    const roundedMinutes = Math.ceil(minutes / 15) * 15;
    return dayjs(date).minute(roundedMinutes).second(0);
  };

  const updateValues = (name, value) => {
    setBillConfiguration({ ...billConfiguration, [name]: value });

    const newBillConfiguration = { ...billConfiguration, [name]: value };

    const newDate = name === "date" ? value : billConfiguration?.date;

    const validatedExecution = handleNextExecution(
      newBillConfiguration.Frequency,
      dayjs(newDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    );

    const dataWithFormat = {
      FK_Bill: Number(id),
      date:
        name === "date"
          ? roundToNext15Minutes(newDate)
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
          : roundToNext15Minutes(dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")),
      Day: roundToNext15Minutes(newDate).date(),
      Month: roundToNext15Minutes(newDate).month() + 1,
      Year: roundToNext15Minutes(newDate).year(),
      Hour: roundToNext15Minutes(newDate).utc().hour(),
      Minute: roundToNext15Minutes(newDate).minute(),
      Frequency: newBillConfiguration.Frequency,
      Active: newBillConfiguration.Active,
      Next_Execution: validatedExecution,
    };

    if (id) {
      updateConfigurationBill({
        variables: {
          billConfiguration: {
            ...dataWithFormat,
          },
        },
      })
        .then(response => {
          refetch();
          toast.success("Cambio exitoso");
        })
        .catch(error => {
          toast.error("Error al programar la factura");
        });
    }
  };

  // UPDATE DATA IF EXISTS
  useEffect(() => {
    if (dataBill?.bill?.BillConfiguration) {
      const configuration = dataBill?.bill?.BillConfiguration;

      setBillConfiguration({
        FK_Bill: Number(id),
        date: dayjs(configuration.date)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        Day: dayjs(configuration?.Day).day(),
        Month: dayjs(configuration?.Month).month() + 1,
        Year: dayjs(configuration?.Year).year(),
        Hour: dayjs(configuration?.Hour).hour(),
        Minute: dayjs(configuration?.Minute).minute(),
        Frequency: configuration?.Frequency,
        Active: configuration?.Active,
        Next_Execution: dayjs(configuration.date)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataBill]);

  const handleOpenModal = () => {
    setModalOpen(
      true,
      <Box
        className="modal-content-size container-modal "
        sx={{
          height: { xs: "600px", sm: "100%" },
          width: { xs: "333px", sm: "100%" },
        }}
      >
        <RegisterReceiver
          newClient
          FK_User={0}
          handleChangeAddress={() => null}
          handleCreateNewClient={addNewClientAddapter}
          handleOnClosed={() => setModalOpen(false)}
          initialReceiver={null}
          setBillingProfile={null}
          title="Perfil de facturación"
        />
      </Box>,
    );
  };

  const addNewClientAddapter = async profile => {
    const providersGroup = dataClientsGroups?.groups?.find(
      ({ Name_ClientGroup }) => Name_ClientGroup === "Clientes",
    );
    const selectedGroup =
      providersGroup?.id ||
      (
        await createGroup({
          Name_ClientGroup: "Clientes",
          FK_Place: LineDetailPlace.id,
        })
      )?.clientGroup?.id;
    refetchGroups();
    if (!selectedGroup) {
      return toast.error("Ocurrió un error al crear el grupo");
    }

    const newClient = await addNewClient(profile, selectedGroup, "Cliente");
    const clientData = newClient?.data?.client;
    const billingProfile = {
      id: clientData?.BillingProfile?.id,
      Receiver_Id: clientData?.BillingProfile?.ID_Number,
      Receiver_Name: clientData?.BillingProfile?.Name,
      Receiver_Email: clientData?.BillingProfile?.Email,
      Receiver_PhoneCode: clientData?.BillingProfile?.PhoneCodCountry,
      Receiver_PhoneNumber: clientData?.BillingProfile?.PhoneNumber,
      Receiver_PersonType: clientData?.BillingProfile?.MH_PersonType,
      user: { id: clientData?.BillingProfile?.User.id },
    };
    const address = {
      Province:
        clientData?.BillingProfile?.MH_Neighborhood?.District?.Canton?.Province,
      Canton: clientData?.BillingProfile?.MH_Neighborhood?.District?.Canton,
      District: clientData?.BillingProfile?.MH_Neighborhood?.District,
      Neighborhood: clientData?.BillingProfile?.MH_Neighborhood,
      Other: clientData?.BillingProfile?.OtherSigns,
    };

    setAddress(address);
    setClientGroup({ id: 0 });
    setModalOpen(false);

    return setReceiver(billingProfile);
  };

  useEffect(() => {
    const fetchText = async () => {
      if (billType && !isDraft) {
        setSubmitInvoiceText(submitText[billType]);
        return;
      }

      const isATV = await getUserByIdentification(receiver.Receiver_Id);
      if (isATV) {
        setSubmitInvoiceText("Generar Factura Electrónica");
        return;
      }
      setSubmitInvoiceText("Generar Tiquete Electrónico");
    };

    fetchText();
  }, [billType, isDraft, receiver.Receiver_Id]);
  // this needs improvements

  return !loadState.isLoading ? (
    <div className={loading ? "loading" : ""}>
      {loading && (
        <div className="container-padding">
          <Loading />
        </div>
      )}
      {!loading && (
        <div className="content-flex-column">
          <Box
            sx={{
              "display": "flex",
              "flexDirection": { xs: "column", sm: "row" },
              "gap": "1rem",
              "& > *": {
                flexBasis: { xs: "100%", sm: "calc(33.333% - 1rem)" },
                flexGrow: 1,
              },
            }}
          >
            {isNote || (
              <Button
                color="light"
                disabled={!lineDetail.length || electronicBill.id}
                size="medium"
                variant="contained"
                onClick={() => handleSaveAsTemp()}
              >
                {getSaveButtonText}
              </Button>
            )}
            {!registeredTicket ? (
              <Button
                color="light"
                disabled={availableSendDocument()}
                size="medium"
                variant="contained"
                onClick={() => handleSendBill()}
              >
                {submitInvoiceText}
              </Button>
            ) : (
              <Button
                color="light"
                disabled={!lineDetail.length}
                size="medium"
                variant="contained"
                onClick={() => navigate("/CreditDebitNote")}
              >
                Crear nota de crédito
              </Button>
            )}
            {electronicBill.id && !isNote && (
              <Button
                color="light"
                size="medium"
                variant="contained"
                onClick={() => openSendEmailModal()}
              >
                Enviar Correo
              </Button>
            )}

            <Box
              sx={{
                display: id && billType === "Draft" ? "flex" : "none",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Typography typography="modeColor" variant="subtitle1">
                Factura programada
              </Typography>
              <Tooltip
                title={
                  scheduledBill?.Active
                    ? "Desactivar factura programada"
                    : "Activar factura programada"
                }
              >
                <Checkbox
                  checked={!!scheduledBill?.Active}
                  defaultValue={false}
                  label="Factura programada"
                  name="scheduledBill"
                  sx={{
                    "&.Mui-checked": {
                      color: "#2CA72A",
                    },
                  }}
                  onChange={value => {
                    setScheduledBill(prev => ({
                      ...prev,
                      Active: !prev.Active,
                    }));
                    updateValues("Active", value.target.checked);
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
          <div className="content-flex-column">
            {scheduledBill?.Active ? (
              <ScheduledBillCard
                data={billConfiguration}
                id={id}
                setBillConfiguration={setBillConfiguration}
                updateValues={updateValues}
              />
            ) : null}
            {isNote && (
              <NotesForm
                code_name="code"
                noteInfo={noteInfo}
                reason_name="reason"
                onChange={setNoteInfo}
              />
            )}
            <Box className="content-grid-column">
              <OptionCard
                goBack
                disabled={isNote}
                img={validateRoute(LineDetailPlace)}
                optional={LineDetailPlace.Name_Place}
                title="Inventarios"
              />
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <OptionCard
                  mainComponent
                  disabled={isNote}
                  icon={addProductIcon}
                  title="Añadir productos"
                />
              </Box>
            </Box>
            <Box
              sx={{
                "display": "grid",
                "grid-template-columns": "repeat(auto-fit, minmax(330px, 1fr))",
                "gap": "1rem",
              }}
            >
              <div className="content-flex-column">
                <ClientCard
                  data={{
                    Id: receiver?.Receiver_Id,
                    Name: receiver?.Receiver_Name,
                    Email: receiver?.Receiver_Email,
                    PhoneNumber: receiver?.Receiver_PhoneNumber,
                  }}
                  optional={
                    receiver.Receiver_Id === ""
                      ? "Click para buscar (opcional)"
                      : ` ${receiver?.Receiver_Name}, 
                        ${receiver?.Receiver_Id}`
                  }
                  optional2={
                    receiver?.Receiver_Email === "" &&
                    receiver?.Receiver_PhoneNumber === ""
                      ? ""
                      : ` ${receiver?.Receiver_Email}, 
                      ${receiver?.Receiver_PhoneNumber}`
                  }
                  type={
                    isNote
                      ? ElectronicBillTypes.CreditNote
                      : ElectronicBillTypes.FacturaElectronica
                  }
                  onClickSubmit={() => handleOpenModal()}
                />
                <PaymentDataCard
                  type={
                    isNote
                      ? ElectronicBillTypes.CreditNote
                      : ElectronicBillTypes.FacturaElectronica
                  }
                />
                <ResumeCard type={getType()} />
              </div>
              <Box sx={{ display: { xs: "block", md: "none" } }}>
                <OptionCard
                  mainComponent
                  disabled={isNote}
                  icon={addProductIcon}
                  title="Añadir productos"
                />
              </Box>
              {lineDetail?.length !== 0 ? (
                <ProductCard
                  carousel
                  list={lineDetail}
                  selectables={
                    getType() === ElectronicBillTypes.CreditNote ||
                    getType() === ElectronicBillTypes.DebitNote
                  }
                  setDataTable={setLineDetail}
                  type={getType()}
                />
              ) : (
                <Card
                  color="primary"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    minHeight: "300px",
                    maxHeight: "700px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="product"
                    className="icon-color"
                    src={withoutIcon}
                    style={{ width: "200px", height: "202px" }}
                  />
                  <Typography
                    sx={{ textAlign: "center" }}
                    typography="modeColor"
                    variant="subtitle1"
                  >
                    No se han agregado productos aún
                  </Typography>
                </Card>
              )}
            </Box>
          </div>
        </div>
      )}
    </div>
  ) : (
    <CustomLoading
      centered
      vCentered
      errorTexts={["Algo ha fallado"]}
      loadingState={loadState.loadingState}
      loadingTexts={["Estamos enviando su factura", "Por favor, espere..."]}
    />
  );
}
export default PaymentComponent;
