import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
// External components
import { Box, Button, Tab, Tabs } from "@mui/material";
// Components
import { toast } from "react-toastify";
import {
  IvaReport,
  ClientListReport,
  ProductsReport,
  SellsReport,
  ElectronicBillReport,
  EmmitedDocumentReport,
} from "..";
// Context
import { useAuth } from "../../context";
// Routes
import { InventoryRequiredRoute } from "../../routes";
// SCSS
import "./MenuReports.scss";
import D104Configuration from "../D104Configuration/D104Configuration";
import { useElectronic } from "../../context/ElectronicContext";
// IMG
import arrowLeft from "../../assets/icons/arrowLeft.svg";
import arrowRight from "../../assets/icons/arrowRight.svg";

function MenuReports() {
  const [toggleState, setToggleState] = useState(1);
  const [viewButton, setViewButton] = useState(0);
  const [dimensions, setDimensions] = useState({
    scrollWidth: 0,
    clientWidth: 0,
  });
  const { permissions } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { LineDetailPlace } = useElectronic();
  const tabsRef = useRef(null);
  const { haveActionPermission, noAccessMessage } = permissions;
  const canEdit = haveActionPermission("Edit", "/reports", LineDetailPlace);
  const canRead = haveActionPermission("Read", "/reports", LineDetailPlace);
  if (!canRead) {
    toast.warning(noAccessMessage("Leer", "Reportes"));
    navigate(`/`);
  }

  const scroll = direction => {
    if (direction === "left") {
      if (viewButton > 2) setViewButton(viewButton - 1);
    }
    if (direction === "right" && viewButton === 2) setViewButton(3);
    if (direction === "right" && viewButton === 3) setViewButton(4);
    if (direction === "right" && viewButton === 4) setViewButton(5);
    if (direction === "right" && viewButton === 5) setViewButton(2);
  };

  const checkOverflow = () => {
    if (tabsRef.current) {
      const { scrollWidth, clientWidth } = tabsRef.current;
      setDimensions({ scrollWidth, clientWidth });
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // MOBILE
    if (dimensions.scrollWidth < 495) {
      setViewButton(2);
    }
    // PC XL
    if (dimensions.scrollWidth > 780) {
      setViewButton(2);
    }
  }, [dimensions]);

  useEffect(() => {
    const routes = [
      { route: "/reports/report01", value: 1 },
      { route: "/reports/report02", value: 2 },
      { route: "/reports/report03", value: 3 },
      { route: "/reports/report04", value: 4 },
      { route: "/reports/report05", value: 5 },
      { route: "/reports/report06", value: 6 },
      { route: "/reports/report07", value: 7 },
    ];
    setToggleState(
      routes.find(index => index.route === location.pathname)?.value || 1,
    );
  }, [location.pathname]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        paddingBottom: 38,
      }}
    >
      <div ref={tabsRef} className="content-flex-row-between">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: "1rem",
            justifyContent: viewButton !== 2 ? "space-between" : "start",
          }}
        >
          <Button
            color="arrowButton"
            sx={{
              border: "none",
              cursor: "pointer",
              display: viewButton === 2 ? "none" : "flex",
              position: "absolute",
              transform: "translateX(-50%)",
              top: { xs: "105px", sm: "140px", md: "150px" },
              zIndex: "1",
              alignItems: "center",
              justifyContent: "center",
              padding: "15px",
            }}
            type="button"
            onClick={() => scroll("left")}
          >
            <img
              alt="izquierda"
              src={arrowLeft}
              style={{ width: "18px", height: "18px" }}
            />
          </Button>
          <Tabs
            sx={{
              "height": { xs: "35px", sm: "50px" },

              "& .MuiTabs-flexContainer": {
                gap: "1rem",
              },
              "margin": 0,
              "width": "100%",
            }}
            value={toggleState}
          >
            <Tab
              as={Link}
              className={`${toggleState === 1 ? "active" : "tabs"}`}
              color="option"
              label="Declaración de IVA(D-104)"
              sx={{
                height: { xs: "35px", sm: "50px" },
                padding: 1,
                maxHeight: "auto",
                minHeight: "auto",
                display: viewButton !== 2 ? "none" : "flex",
                justifyContent: "center",
                borderRadius: " 5px",
              }}
              to="/reports/report01"
            />
            {canEdit && (
              <Tab
                as={Link}
                className={`${toggleState === 2 ? "active" : "tabs"}`}
                color="option"
                label="Configuración D-104"
                sx={{
                  height: { xs: "35px", sm: "50px" },
                  padding: 1,
                  maxHeight: "auto",
                  minHeight: "auto",
                  display: viewButton !== 2 ? "none" : "flex",
                  justifyContent: "center",
                  borderRadius: " 5px",
                }}
                to="/reports/report02"
              />
            )}

            <Tab
              as={Link}
              className={`${toggleState === 3 ? "active" : "tabs"}`}
              color="option"
              label="Lista de clientes (PDF)"
              sx={{
                height: { xs: "35px", sm: "50px" },
                display:
                  (viewButton === 3 && dimensions.scrollWidth <= 500) ||
                  (viewButton === 2 && dimensions.scrollWidth > 500)
                    ? "flex"
                    : "none",
                padding: 1,
                maxHeight: "auto",
                minHeight: "auto",
                justifyContent: "center",
                borderRadius: " 5px",
              }}
              to="/reports/report03"
            />
            <Tab
              as={Link}
              className={`${toggleState === 4 ? "active" : "tabs"}`}
              color="option"
              label="Detalles de productos (PDF)"
              sx={{
                height: { xs: "35px", sm: "50px" },
                display:
                  (viewButton === 3 && dimensions.scrollWidth <= 700) ||
                  (viewButton === 2 && dimensions.scrollWidth > 700)
                    ? "flex"
                    : "none",
                padding: 1,
                maxHeight: "auto",
                minHeight: "auto",
                justifyContent: "center",
                borderRadius: " 5px",
              }}
              to="/reports/report04"
            />
            <Tab
              as={Link}
              className={`${toggleState === 5 ? "active" : "tabs"}`}
              color="option"
              label="Informe de ventas (CSV)"
              sx={{
                height: { xs: "35px", sm: "50px" },
                padding: 1,
                maxHeight: "auto",
                minHeight: "auto",
                display:
                  (viewButton === 4 && dimensions.scrollWidth <= 500) ||
                  (viewButton === 3 &&
                    dimensions.scrollWidth > 500 &&
                    dimensions.scrollWidth < 930) ||
                  (viewButton === 2 && dimensions.scrollWidth > 930)
                    ? "flex"
                    : "none",
                justifyContent: "center",
                borderRadius: " 5px",
              }}
              to="/reports/report05"
            />
            <Tab
              as={Link}
              className={`${toggleState === 6 ? "active" : "tabs"}`}
              color="option"
              label="Factura Electrónica(PDF)"
              sx={{
                height: { xs: "35px", sm: "50px" },
                padding: 1,
                maxHeight: "auto",
                minHeight: "auto",
                display:
                  (viewButton === 4 && dimensions.scrollWidth <= 500) ||
                  (viewButton === 3 && dimensions.scrollWidth > 500) ||
                  (viewButton === 2 && dimensions.scrollWidth > 980)
                    ? "flex"
                    : "none",
                justifyContent: "center",
                borderRadius: " 5px",
              }}
              to="/reports/report06"
            />
            <Tab
              as={Link}
              className={`${toggleState === 7 ? "active" : "tabs"}`}
              color="option"
              label="Documentos Emitidos (zip)"
              sx={{
                height: { xs: "35px", sm: "50px" },
                padding: 1,
                maxHeight: "auto",
                minHeight: "auto",
                display:
                  (viewButton === 5 && dimensions.scrollWidth <= 500) ||
                  (viewButton === 4 && dimensions.scrollWidth > 500) ||
                  (viewButton === 3 && dimensions.scrollWidth > 700)
                    ? "flex"
                    : "none",
                justifyContent: "center",
                borderRadius: " 5px",
              }}
              to="/reports/report07"
            />
          </Tabs>
        </div>
        <Button
          color="arrowButton"
          sx={{
            border: "none",
            cursor: "pointer",
            display:
              (viewButton >= 5 && dimensions.scrollWidth <= 500) ||
              (viewButton >= 4 && dimensions.scrollWidth > 500) ||
              (viewButton >= 3 && dimensions.scrollWidth > 700)
                ? "none"
                : "flex",
            position: "absolute",
            right: "1px",
            top: { xs: "105px", sm: "140px", md: "150px" },
            zIndex: "10",
            alignItems: "center",
            justifyContent: "center",
            padding: "15px",
          }}
          tabIndex={0}
          type="button"
          onClick={() => scroll("right")}
        >
          <img
            alt="derecha"
            src={arrowRight}
            style={{ width: "18px", height: "18px" }}
          />
        </Button>
      </div>
      <Routes>
        <Route element={<IvaReport />} path="report01" />
        {canEdit && <Route element={<D104Configuration />} path="report02" />}
        <Route element={<ClientListReport />} path="report03" />
        <Route
          element={<InventoryRequiredRoute element={ProductsReport} />}
          path="report04"
        />
        <Route
          element={<InventoryRequiredRoute element={SellsReport} />}
          path="report05"
        />
        <Route
          element={<InventoryRequiredRoute element={ElectronicBillReport} />}
          path="report06"
        />
        <Route
          element={<InventoryRequiredRoute element={EmmitedDocumentReport} />}
          path="report07"
        />
        <Route element={<IvaReport />} path="" />
      </Routes>
    </Box>
  );
}
export default MenuReports;
